<template>
  <div>
    <div>
      <h1 style="text-align:center; font-size: 25px; padding: 25px;">
        Create Team
      </h1>
      <input
        type="text"
        class="loginInput"
        placeholder="Team Name"
        name="First Name"
        v-model="teamName"
        required
        autofocus
      />
      <button id="LoginButton" @click="createTeam()">Create</button>
      <p style="text-align: center">{{ teamCreationMessage }}</p>
    </div>

    <h1
      style="text-align:center; font-size: 25px; padding: 25px;"
      v-if="selectedMenu == 3"
    >
      Select Team to Register
    </h1>

    <select
      v-model="selectedTeam"
      @click="getYourTeams()"
      class="selection"
      v-if="selectedMenu == 3"
    >
      <option class="option" selected disabled value="">Select Team</option>
      <option
        v-for="(team, index) in yourTeams"
        v-bind:key="index"
        v-bind:value="team"
        class="option"
        >{{ team.Name }}
      </option>
    </select>

    <div class="registrations">
      <h1
        style="text-align:center; font-size:20px; padding: 15px;"
        class="menuTitle"
      >
        Team Registration
      </h1>
      <select
        v-model="selectedRegistration"
        @change="
          getDivisions(
            selectedRegistration.league_id,
            selectedRegistration.season_id
          )
        "
        @click="getRegistrations"
        class="selection"
      >
        <option class="option" selected disabled value=""
          >Select Registration</option
        >
        <option
          v-for="(registration, index) in registrations"
          v-bind:key="index"
          v-bind:value="registration"
          class="option"
          >{{ registration.seasonName }} - {{ registration.leagueName }}</option
        >
      </select>

      <select
        v-if="selectedRegistration"
        v-model="selectedDivision"
        @change="
          showPrice = true;
          lookRegistration();
        "
        class="selection"
      >
        <option class="option" selected disabled value=""
          >Select Division</option
        >
        <option
          class="option"
          v-for="(division, index) in divisions"
          v-bind:key="index"
          v-bind:value="division"
          :disabled="division.Teams.length == selectedRegistration.team_limit"
          :class="[
            division.Teams.length == selectedRegistration.team_limit
              ? 'closed'
              : 'open',
          ]"
          >{{ division.Name }} - {{ division.Teams.length }}/{{
            selectedRegistration.team_limit
          }}</option
        >
      </select>

      <div v-if="showPrice" class="paymentInfo">
        <p>
          Payed by: <b>{{ selectedRegistration.payed_by }}</b>
        </p>
        <p>
          Price: <b>${{ selectedRegistration.price }}</b>
        </p>
        <p>
          Player Limit: <b>{{ selectedRegistration.player_limit }}</b>
        </p>
      </div>

      <button
        class="Button"
        :disabled="!showPrice"
        @click="
          !alreadyRegistered
            ? (payment = true)
            : (message = 'Already registered for this season')
        "
      >
        REGISTER
      </button>
      <p style="text-align: center">{{ message }}</p>

      <Payment
        v-if="payment == true && !selectedInvitation && selectedTeam"
        :selectedRegistration="selectedRegistration"
        :Client_id="selectedTeam._id"
        :selectedDivision="selectedDivision"
        :playerPage="false"
        @updateState="updateDataState"
        @getTeamRegistrations="getTeamRegistrations"
      />
    </div>

    <div class="invitations">
      <h1
        style="text-align:center; font-size:20px; padding: 15px;"
        class="menuTitle"
      >
        Invite Players to Roster
      </h1>

      <select
        v-model="selectedRegistrationForInvite"
        @click="getTeamRegistrations()"
        @change="
          () => {
            this.getRosterPlayers();
            this.getInvitedPlayers();
          }
        "
        class="selection"
      >
        <option class="option" selected disabled value=""
          >Select Registration</option
        >
        <option
          class="option"
          v-for="(registration, index) in teamRegistrations"
          v-bind:key="index"
          v-bind:value="registration"
          >{{ registration.leagueName }} - {{ registration.seasonName }}</option
        >
      </select>

      <div class="cards" v-if="selectedRegistrationForInvite">
        <div
          v-for="(obj, index) in rosterPlayers"
          v-bind:key="index"
          class="playerCard"
        >
          <img
            class="playerPicture"
            src="@/assets/default-player.png"
            style="max-width: 100%; max-height: 140px; margin-top 5px;"
          />
          <p class="invite">
            {{ obj.Player[0].First_Name }} {{ obj.Player[0].Last_Name }}
          </p>
        </div>

        <div
          v-for="(obj, index) in invitedPlayers"
          v-bind:key="index"
          class="playerCard"
        >
          <img
            class="playerPicture"
            src="@/assets/default-player.png"
            style="max-width: 100%; max-height: 140px; margin-top 5px;"
          />
          <p class="invite">
            Invited {{ obj.Player[0].First_Name }}
            {{ obj.Player[0].Last_Name }}
          </p>
          <button class="Cancel" @click="cancelInvite(obj._id)">
            Cancel Invite
          </button>
        </div>

        <div
          v-for="index in 5"
          v-bind:key="index"
          class="playerCard"
          @click="clicked = true"
        >
          <p class="plus">+</p>
        </div>

        <div class="popup" v-if="clicked == true">
          <div
            class="background"
            @click="
              () => {
                clicked = false;
                selectedPlayer = '';
              }
            "
          ></div>
          <div class="box">
            <SearchPlayerToInvite
              @search-function="selectedPlayer = $event"
              :registrationID="selectedRegistrationForInvite.Registration_id"
            />
            <p>
              SELECTED:
              <b
                >{{ selectedPlayer.First_Name }}
                {{ selectedPlayer.Last_Name }}</b
              >
            </p>
            <button class="Button" :disabled="!selectedPlayer" @click="invite">
              INVITE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Payment from "@/components/Payment.vue";
import SearchPlayerToInvite from "@/components/home/SearchPlayerToInvite.vue";
export default {
  components: {
    Payment,
    SearchPlayerToInvite,
  },
};
</script>

<style lang="scss" scoped></style>
