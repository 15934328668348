<template>
  <div>
    <div class="generalInfo">
      <div class="Picture">
        <template v-if="profile.playerPicture">
          <source
            class="playerPicture"
            :srcset="`${$bucketUrl}/images/players/${profile.Player_id}.webp`"
            type="image/webp"
          />
        </template>
        <template v-else>
          <source srcset="@/assets/default-player.png" type="image/png" />
          <img class="playerPicture" src="@/assets/default-player.png" />
        </template>
      </div>

      <div class="Name">
        <p>{{ profile.First_Name }}</p>
        <p>{{ profile.Last_Name }}</p>
      </div>
    </div>

    <div v-if="stats.length != 0">
      <PlayerStatsTable
        :content="stats"
        v-if="stats.length != 0"
        class="statTable"
      />
    </div>

    <div class="invitations">
      <h1 id="headLine" class="menuTitle">INVITATIONS</h1>

      <div
        v-for="(invitation, index) in invitations"
        :key="index"
        class="invitation"
      >
        <p>
          <b>{{ invitation.Team[0].CapName }}</b> has invited you to join
          <b>{{ invitation.Team[0].Name }}</b> to play in
          <b>{{ invitation.League[0].FullName }}</b>
          for season <b>{{ invitation.Season[0].Name }}</b>
        </p>

        <p>
          Registration fee is payed by:
          <b>{{ invitation.Registration[0].payed_by }}</b>
        </p>

        <p
          v-if="
            invitation.Registration[0].payed_by == 'Player' &&
              !alreadyPayed(invitation.Registration[0]._id)
          "
        >
          Total to pay: <b>${{ invitation.Registration[0].price }}</b>
        </p>
        <p
          v-if="
            invitation.Registration[0].payed_by != 'Player' ||
              alreadyPayed(invitation.Registration[0]._id)
          "
        >
          Total to pay: <b>$0</b>
        </p>
        <button
          class="InviteButton"
          style="background-color:green"
          @click="
            payment = true;
            selectedInvitation = invitation;
          "
        >
          Accept
        </button>

        <button class="InviteButton" @click="decline(invitation._id)">
          Decline
        </button>

        <p>{{ messagePlayer }}</p>
      </div>

      <Payment
        v-if="payment == true && selectedInvitation"
        :selectedRegistration="selectedInvitation.Registration[0]"
        :Client_id="profile.Player_id"
        :Invitation_id="selectedInvitation._id"
        :Team_id="selectedInvitation.Team_id"
        :selectedDivision="selectedInvitation.Registration[0].league_id"
        :playerPage="true"
        :paymentAlreadyMade="
          alreadyPayed(selectedInvitation.Registration[0]._id)
        "
        @updateState="updateDataState"
        @updateInvitations="updateInvitations"
      />
    </div>
  </div>
</template>

<script>
import PlayerStatsTable from "@/components/PlayerStatsTable.vue";
import axios from "axios";
import jwtDecode from "jwt-decode";
export default {
  components: {
    PlayerStatsTable,
  },
  props: [],
  data() {
    return {
      profile: null,
      invitations: [],
      messagePlayer: "",
      stats: [],
      payment: false,
      selectedInvitation: null,
    };
  },
  methods: {
    async getInvitations() {
      axios
        .get(
          this.$apiUrl +
            "/registration/player/invitations?Player_id=" +
            this.Player_id
        )
        .then((res) => {
          this.invitations = res.data;
        });
    },
    async getStats() {
      axios
        .get(this.$apiUrl + "/players/" + this.profile.Player_id + "/stats")
        .then((res) => {
          if (res.status == 200) {
            this.stats = res.data;
          }
        });
    },
    async alreadyPayed(id) {
      for (let reg of this.currentPlayerRegistrations) {
        if (reg.Registration_id == id) {
          return true;
        }
      }
      this.alreadyRegisteredPlayer = false;
      this.messagePlayer = "";
    },
    async decline(Invitation_id) {
      await axios
        .post(
          this.$apiUrl + "/registration/player/decline",
          {
            Invitation_id: Invitation_id,
          },
          {
            headers: {
              token: `${localStorage.userToken}`,
            },
          }
        )
        .then(async (res) => {
          this.messagePlayer = res.data;
        });

      this.getInvitations();
    },
  },
  created() {
    if (localStorage.userToken) {
      let token = localStorage.userToken;
      let decoded = jwtDecode(token);
      this.profile = decoded;
      this.First_Name = decoded.First_Name;
      this.Last_Name = decoded.Last_Name;
      this.playerPicture = decoded.playerPicture;
      this.Player_id = decoded.Player_id;
    }
    this.getInvitations();
    this.getStats();
  },
};
</script>

<style lang="scss" scoped>
#headLine {
  text-align: center;
  font-size: 20px;
  padding: 15px;
}
.statTable {
  width: 90%;
  margin-left: 5%;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.589);
}
.generalInfo {
  display: flex;
  width: 90%;
  margin-left: 5%;
}

.playerPicture {
  height: 200px;
  width: 200px;
}
.invitations {
  width: 80%;
  margin-left: 10%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.329);
  margin-top: 50px;
  margin-bottom: 50px;
  height: 400px;
  overflow-y: auto;
}
.Name {
  display: flex;
  flex-direction: column;
  font-size: 25px;

  p {
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 5px;
  }

  p:first-child {
    margin-top: 25%;
  }
}
.invitation {
  width: 80%;
  margin-left: 10%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.562);
  height: 175px;
  margin-bottom: 40px;
  text-align: center;
  padding-top: 20px;

  p {
    padding: 5px 20px 5px 20px;
  }
}
.InviteButton {
  width: 150px;
  height: 30px;
  margin-left: 20px;
  margin-top: 10px;
  padding: 5px 50px 5px 50px;
  background-color: rgb(141, 30, 3);
  color: rgb(255, 255, 255);
  font-size: 14px;
  letter-spacing: 1px;
  border: 0px solid;
  cursor: pointer;
}
</style>
