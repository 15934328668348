var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"registrations"},[_c('h1',{staticClass:"menuTitle",staticStyle:{"text-align":"center","font-size":"20px","padding":"15px"}},[_vm._v(" Player Registration ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRegistrationPlayer),expression:"selectedRegistrationPlayer"}],staticClass:"selection",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedRegistrationPlayer=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.getDivisions(
          _vm.selectedRegistrationPlayer.league_id,
          _vm.selectedRegistrationPlayer.season_id
        );
        _vm.showPricePlayer = false;}],"click":_vm.getRegistrations}},[_c('option',{staticClass:"option",attrs:{"selected":"","disabled":"","value":""}},[_vm._v("Select Registration")]),_vm._l((_vm.playerRegistrations),function(registration,index){return _c('option',{key:index,staticClass:"option",domProps:{"value":registration}},[_vm._v(_vm._s(registration.seasonName)+" - "+_vm._s(registration.leagueName))])})],2),(_vm.selectedRegistrationPlayer)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDivisionPlayer),expression:"selectedDivisionPlayer"}],staticClass:"selection",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDivisionPlayer=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.showPricePlayer = true;
        _vm.lookRegistrationPlayer();}]}},[_c('option',{staticClass:"option",attrs:{"selected":"","disabled":"","value":""}},[_vm._v("Select Division")]),_vm._l((_vm.divisions),function(division,index){return _c('option',{key:index,staticClass:"option",class:[
          division.Teams.length == _vm.selectedRegistrationPlayer.team_limit
            ? 'closed'
            : 'open',
        ],attrs:{"disabled":division.Teams.length == _vm.selectedRegistrationPlayer.team_limit},domProps:{"value":division}},[_vm._v(_vm._s(division.Name)+" ")])})],2):_vm._e(),(_vm.showPricePlayer)?_c('div',{staticClass:"paymentInfo"},[_c('p',[_vm._v(" Payed by: "),_c('b',[_vm._v(_vm._s(_vm.selectedRegistrationPlayer.payed_by))])]),_c('p',[_vm._v(" Price: "),_c('b',[_vm._v("$"+_vm._s(_vm.selectedRegistrationPlayer.price))])])]):_vm._e(),(_vm.payment == true)?_c('Payment',{attrs:{"selectedRegistration":_vm.selectedRegistrationPlayer,"Client_id":_vm.Player_id,"selectedDivision":_vm.selectedDivisionPlayer,"playerPage":true},on:{"updateState":_vm.updateDataState}}):_vm._e(),_c('button',{staticClass:"Button",attrs:{"disabled":!_vm.showPricePlayer},on:{"click":function($event){!_vm.alreadyRegisteredPlayer
          ? (_vm.payment = true)
          : (_vm.messagePlayer = 'Already registered.')}}},[_vm._v(" REGISTER ")]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.messagePlayer))])],1),_c('div',{staticClass:"registrations"},[_c('h1',{staticClass:"menuTitle",staticStyle:{"text-align":"center","font-size":"20px","padding":"15px"}},[_vm._v(" My Registrations ")]),_vm._l((_vm.currentPlayerRegistrations),function(registration,index){return _c('div',{key:index,staticClass:"registered"},[_c('p',[_vm._v(" League: "),_c('b',[_vm._v(_vm._s(registration.leagueName))]),_vm._v(" Season: "),_c('b',[_vm._v(_vm._s(registration.seasonName))])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }