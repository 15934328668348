<template>
  <table>
    <caption v-if="xTitle">
      {{
        xTitle
      }}
    </caption>
    <thead>
      <tr>
        <th
          v-for="(column, ci) in xColumns"
          :key="ci"
          @click="headClick(column)"
          :class="{
            sortable: column.sortable,
            sorted: currentSort == column.name,
            left: column.align == 'left',
            center: column.align == 'center',
            right: column.align == 'right',
            hideable: column.hideable,
            compactable: column.compactable,
            minimal: column.minimal,
          }"
        >
          <slot :name="'header[' + column.name + ']'" :column="column">
            <template v-if="column.t">{{ $t(column.t) }}</template>
            <template v-else>{{ column.display }}</template>
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, ri) in sorted" :key="ri" @click="rowClick(row)">
        <td
          v-for="(cell, ci) in xColumns"
          :key="ci"
          :class="{
            left: cell.align == 'left',
            center: cell.align == 'center',
            right: cell.align == 'right',
            hideable: cell.hideable,
            compactable: cell.compactable,
            minimal: cell.minimal,
          }"
        >
          <slot
            :name="'cell[' + cell.name + ']'"
            :row="row"
            :cell="row[cell.name]"
            :row-index="ri"
            >{{ row[cell.name] }}</slot
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["xContent", "xColumns", "xTitle", "sortBy", "sortDir"],
  data() {
    return {
      currentSort: null,
      currentSortDir: null,
    };
  },
  created() {
    if (this.sortBy) {
      this.currentSort = this.sortBy;
      if (this.sortDir) {
        this.currentSortDir = this.sortDir;
      } else {
        this.currentSortDir = "asc";
      }
    }
  },
  methods: {
    rowClick(id) {
      this.$emit("row-click", id);
    },
    headClick(column) {
      if (column.sortable) {
        this.sort(column);
      }
    },
    sort: function(column) {
      if (column.name === this.currentSort) {
        this.currentSortDir = this.currentSortDir !== "desc" ? "desc" : "asc";
      } else {
        this.currentSort = column.name;
        this.currentSortDir = column.preferDesc ? "desc" : "asc";
      }
    },
  },
  computed: {
    sorted: function() {
      return this.xContent.slice().sort((a, b) => {
        if (this.currentSortDir === null) return 0;
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (isNaN(a[this.currentSort]) || isNaN(b[this.currentSort])) {
          return a[this.currentSort].localeCompare(b[this.currentSort]) * modifier;
        }
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.number {
  min-width: 30px;
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.minimal {
  width: 1%;
  white-space: nowrap;
}
.sortable {
  cursor: pointer;
  &:hover {
    background-color: #00000022;
  }
}
a {
  color: black;
  text-decoration: none;
  display: block;
  &:hover {
    text-decoration: underline;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .hideable {
    display: none;
  }
  .compactable {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
