var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"generalInfo"},[_c('div',{staticClass:"Picture"},[(_vm.profile.playerPicture)?[_c('source',{staticClass:"playerPicture",attrs:{"srcset":`${_vm.$bucketUrl}/images/players/${_vm.profile.Player_id}.webp`,"type":"image/webp"}})]:[_c('source',{attrs:{"srcset":require("@/assets/default-player.png"),"type":"image/png"}}),_c('img',{staticClass:"playerPicture",attrs:{"src":require("@/assets/default-player.png")}})]],2),_c('div',{staticClass:"Name"},[_c('p',[_vm._v(_vm._s(_vm.profile.First_Name))]),_c('p',[_vm._v(_vm._s(_vm.profile.Last_Name))])])]),(_vm.stats.length != 0)?_c('div',[(_vm.stats.length != 0)?_c('PlayerStatsTable',{staticClass:"statTable",attrs:{"content":_vm.stats}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"invitations"},[_c('h1',{staticClass:"menuTitle",attrs:{"id":"headLine"}},[_vm._v("INVITATIONS")]),_vm._l((_vm.invitations),function(invitation,index){return _c('div',{key:index,staticClass:"invitation"},[_c('p',[_c('b',[_vm._v(_vm._s(invitation.Team[0].CapName))]),_vm._v(" has invited you to join "),_c('b',[_vm._v(_vm._s(invitation.Team[0].Name))]),_vm._v(" to play in "),_c('b',[_vm._v(_vm._s(invitation.League[0].FullName))]),_vm._v(" for season "),_c('b',[_vm._v(_vm._s(invitation.Season[0].Name))])]),_c('p',[_vm._v(" Registration fee is payed by: "),_c('b',[_vm._v(_vm._s(invitation.Registration[0].payed_by))])]),(
          invitation.Registration[0].payed_by == 'Player' &&
            !_vm.alreadyPayed(invitation.Registration[0]._id)
        )?_c('p',[_vm._v(" Total to pay: "),_c('b',[_vm._v("$"+_vm._s(invitation.Registration[0].price))])]):_vm._e(),(
          invitation.Registration[0].payed_by != 'Player' ||
            _vm.alreadyPayed(invitation.Registration[0]._id)
        )?_c('p',[_vm._v(" Total to pay: "),_c('b',[_vm._v("$0")])]):_vm._e(),_c('button',{staticClass:"InviteButton",staticStyle:{"background-color":"green"},on:{"click":function($event){_vm.payment = true;
          _vm.selectedInvitation = invitation;}}},[_vm._v(" Accept ")]),_c('button',{staticClass:"InviteButton",on:{"click":function($event){return _vm.decline(invitation._id)}}},[_vm._v(" Decline ")]),_c('p',[_vm._v(_vm._s(_vm.messagePlayer))])])}),(_vm.payment == true && _vm.selectedInvitation)?_c('Payment',{attrs:{"selectedRegistration":_vm.selectedInvitation.Registration[0],"Client_id":_vm.profile.Player_id,"Invitation_id":_vm.selectedInvitation._id,"Team_id":_vm.selectedInvitation.Team_id,"selectedDivision":_vm.selectedInvitation.Registration[0].league_id,"playerPage":true,"paymentAlreadyMade":_vm.alreadyPayed(_vm.selectedInvitation.Registration[0]._id)},on:{"updateState":_vm.updateDataState,"updateInvitations":_vm.updateInvitations}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }