<template>
  <XTable :x-content="content" :x-columns="columns">
    <template #header[Goals]>
      <span class="icon">&#xe800;</span>
    </template>
    <template #header[Assists]>
      <span class="icon">&#xe801;</span>
    </template>
    <template #header[YellowCard]>
      <span class="icon yellow">&#xe802;</span>
    </template>
    <template #header[Double_Yellow_Card]>
      <span class="icon yellow">&#xe803;</span>
    </template>
    <template #header[RedCard]>
      <span class="icon red">&#xe802;</span>
    </template>
    <template #cell[League]="{row,cell}">
      <router-link :to="`/league/${row.League_id}/${row.Season_id}`">{{ cell }}</router-link>
    </template>
    <template #cell[Team]="{row,cell}">
      <router-link :to="`/team/${row.Team_id}/${row.League_id}/${row.Season_id}`">{{ cell }}</router-link>
    </template>
  </XTable>
</template>

<script>
import XTable from "@/components/tables/XTable.vue";
export default {
  components: {
    XTable,
  },
  props: ["content"],
  data() {
    return {
      columns: [
        { t: "common.league", name: "League" },
        { t: "common.season", name: "Season" },
        { t: "common.team", name: "Team" },
        { t: "stats.gp", name: "GamesPlayed", align: "center", compactable: true },
        { name: "Goals", align: "center", compactable: true },
        { name: "Assists", align: "center", compactable: true },
        { name: "YellowCard", align: "center", compactable: true },
        { name: "Double_Yellow_Card", align: "center", compactable: true },
        { name: "RedCard", align: "center", compactable: true },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.stats {
  min-width: 30px;
  text-align: center;
}
.yellow {
  color: yellow;
}
.red {
  color: red;
}
.icon {
  line-height: 18px;
  font-size: 18px;
}
</style>
