<template>
  <div>
    <div class="registrations">
      <h1
        style="text-align:center; font-size:20px; padding: 15px;"
        class="menuTitle"
      >
        Player Registration
      </h1>
      <select
        v-model="selectedRegistrationPlayer"
        @change="
          getDivisions(
            selectedRegistrationPlayer.league_id,
            selectedRegistrationPlayer.season_id
          );
          showPricePlayer = false;
        "
        @click="getRegistrations"
        class="selection"
      >
        <option class="option" selected disabled value=""
          >Select Registration</option
        >
        <option
          v-for="(registration, index) in playerRegistrations"
          v-bind:key="index"
          v-bind:value="registration"
          class="option"
          >{{ registration.seasonName }} - {{ registration.leagueName }}</option
        >
      </select>

      <select
        v-if="selectedRegistrationPlayer"
        v-model="selectedDivisionPlayer"
        @change="
          showPricePlayer = true;
          lookRegistrationPlayer();
        "
        class="selection"
      >
        <option class="option" selected disabled value=""
          >Select Division</option
        >
        <option
          class="option"
          v-for="(division, index) in divisions"
          v-bind:key="index"
          v-bind:value="division"
          :disabled="
            division.Teams.length == selectedRegistrationPlayer.team_limit
          "
          :class="[
            division.Teams.length == selectedRegistrationPlayer.team_limit
              ? 'closed'
              : 'open',
          ]"
          >{{ division.Name }}
        </option>
      </select>

      <div v-if="showPricePlayer" class="paymentInfo">
        <p>
          Payed by: <b>{{ selectedRegistrationPlayer.payed_by }}</b>
        </p>
        <p>
          Price: <b>${{ selectedRegistrationPlayer.price }}</b>
        </p>
      </div>

      <Payment
        v-if="payment == true"
        :selectedRegistration="selectedRegistrationPlayer"
        :Client_id="Player_id"
        :selectedDivision="selectedDivisionPlayer"
        :playerPage="true"
        @updateState="updateDataState"
      />

      <button
        class="Button"
        :disabled="!showPricePlayer"
        @click="
          !alreadyRegisteredPlayer
            ? (payment = true)
            : (messagePlayer = 'Already registered.')
        "
      >
        REGISTER
      </button>
      <p style="text-align: center">{{ messagePlayer }}</p>
    </div>

    <div class="registrations">
      <h1
        style="text-align:center; font-size:20px; padding: 15px;"
        class="menuTitle"
      >
        My Registrations
      </h1>
      <div
        v-for="(registration, index) in currentPlayerRegistrations"
        :key="index"
        class="registered"
      >
        <p>
          League: <b>{{ registration.leagueName }}</b> Season:
          <b>{{ registration.seasonName }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jwtDecode from "jwt-decode";
import Payment from "@/components/Payment.vue";
export default {
  components: {
    Payment,
  },
  data() {
    return {
      selectedRegistrationPlayer: null,
      divisions: [],
      showPricePlayer: false,
      registrations: [],
      playerRegistrations: [],
      Player_id: "",
      selectedDivisionPlayer: null,
      currentPlayerRegistrations: [],
      alreadyRegisteredPlayer: false,
      messagePlayer: "",
      payment: false,
    };
  },
  methods: {
    async getDivisions(league_id, season_id) {
      axios
        .get(
          this.$apiUrl +
            "/registration/divisions?league_id=" +
            league_id +
            "&season_id=" +
            season_id
        )
        .then((res) => {
          this.divisions = res.data;
        });
    },
    async getRegistrations() {
      axios.get(this.$apiUrl + "/registration/player").then((res) => {
        this.registrations = res.data;
      });
    },
    async getPlayerRegistrations() {
      axios.get(this.$apiUrl + "/registration/player").then((res) => {
        this.playerRegistrations = res.data;
      });
    },
    updateDataState(payment) {
      this.payment = payment;
      this.getCurrentPlayerRegistrations();
    },
    async getCurrentPlayerRegistrations() {
      axios
        .get(
          this.$apiUrl +
            "/registration/player/registred?Player_id=" +
            this.Player_id
        )
        .then((res) => {
          this.currentPlayerRegistrations = res.data;
        });
    },
    async lookRegistrationPlayer() {
      for (let reg of this.currentPlayerRegistrations) {
        if (reg.Registration_id == this.selectedRegistrationPlayer._id) {
          this.alreadyRegisteredPlayer = true;
          return;
        }
      }
      this.alreadyRegisteredPlayer = false;
      this.messagePlayer = "";
    },
  },
  created() {
    if (localStorage.userToken) {
      let token = localStorage.userToken;
      let decoded = jwtDecode(token);
      this.Player_id = decoded.Player_id;
    }
    this.getCurrentPlayerRegistrations();
    this.getRegistrations();
    this.getPlayerRegistrations();
  },
};
</script>

<style lang="scss" scoped>
.registrations {
  height: 450px;
  width: 80%;
  margin-left: 10%;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.589);
  margin-top: 50px;
  margin-bottom: 50px;
  display: block;
  overflow-y: auto;
}
.menuTitle {
  background-color: #ffc72c;
  background-image: url(/img/partern-opt5.4a5eff33.svg),
    radial-gradient(circle, #ffc72c, #c59100);
  background-blend-mode: screen, normal;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  color: rgb(26, 26, 26);
}
.selection {
  border: 0;
  padding: 8px 0;
  border-bottom: 1px solid rgb(66, 66, 66);
  width: 450px;
  height: 37px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  margin-bottom: 20px;
}
.paymentInfo {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
}
.Button {
  width: 250px;
  height: 40px;
  margin-top: 30px;
  padding: 5px 50px 5px 50px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 15px;
  letter-spacing: 1px;
  border: 0px solid;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  cursor: pointer;
}
.registered {
  text-align: center;
  padding: 10px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.39);
}
</style>
