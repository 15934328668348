var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',[(_vm.xTitle)?_c('caption',[_vm._v(" "+_vm._s(_vm.xTitle)+" ")]):_vm._e(),_c('thead',[_c('tr',_vm._l((_vm.xColumns),function(column,ci){return _c('th',{key:ci,class:{
          sortable: column.sortable,
          sorted: _vm.currentSort == column.name,
          left: column.align == 'left',
          center: column.align == 'center',
          right: column.align == 'right',
          hideable: column.hideable,
          compactable: column.compactable,
          minimal: column.minimal,
        },on:{"click":function($event){return _vm.headClick(column)}}},[_vm._t('header[' + column.name + ']',function(){return [(column.t)?[_vm._v(_vm._s(_vm.$t(column.t)))]:[_vm._v(_vm._s(column.display))]]},{"column":column})],2)}),0)]),_c('tbody',_vm._l((_vm.sorted),function(row,ri){return _c('tr',{key:ri,on:{"click":function($event){return _vm.rowClick(row)}}},_vm._l((_vm.xColumns),function(cell,ci){return _c('td',{key:ci,class:{
          left: cell.align == 'left',
          center: cell.align == 'center',
          right: cell.align == 'right',
          hideable: cell.hideable,
          compactable: cell.compactable,
          minimal: cell.minimal,
        }},[_vm._t('cell[' + cell.name + ']',function(){return [_vm._v(_vm._s(row[cell.name]))]},{"row":row,"cell":row[cell.name],"rowIndex":ri})],2)}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }