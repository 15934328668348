<template>
  <div class="website-container">
    <div class="menu">
      <div
        class="menuItem"
        @click="selectedMenu = 1"
        v-bind:class="[selectedMenu == 1 ? 'activeMenu' : null]"
      >
        Dashboard
      </div>
      <div
        class="menuItem"
        @click="selectedMenu = 2"
        v-bind:class="[selectedMenu == 2 ? 'activeMenu' : null]"
      >
        Player Registration
      </div>
      <div
        class="menuItem"
        @click="selectedMenu = 3"
        v-bind:class="[selectedMenu == 3 ? 'activeMenu' : null]"
      >
        Team Registration
      </div>
    </div>

    <!-- COMPONENTS-->
    <Profile v-if="selectedMenu == 1" />
    <PlayerRegistration v-if="selectedMenu == 2" />
    <TeamRegistration v-if="selectedMenu == 3" />
  </div>
</template>

<!-- Script for the menu -->

<script>
import jwtDecode from "jwt-decode";
import axios from "axios";
import Profile from "@/components/playerPanel/profile.vue";
import PlayerRegistration from "@/components/playerPanel/playerRegistration.vue";
import TeamRegistration from "@/components/playerPanel/teamRegistration.vue";
export default {
  components: {
    Profile,
    PlayerRegistration,
    TeamRegistration,
  },
  data() {
    return {
      First_Name: "",
      Last_Name: "",
      playerPicture: "",
      stats: [],
      Player_id: "",
      invitations: [],
      payment: false,
      message: "",
      messagePlayer: "",
      selectedInvitation: null,
      registrations: [],
      selectedRegistration: "",
      selectedRegistrationPlayer: "",
      selectedDivision: "",
      selectedDivisionPlayer: "",
      divisions: [],
      selectedRegistrationForInvite: "",
      teamRegistrations: [],
      showPrice: false,
      showPricePlayer: false,
      alreadyRegistered: false,
      Team_id: "",
      playerRegistrations: [],
      currentPlayerRegistrations: [],
      alreadyRegisteredPlayer: false,
      teamName: "",
      teamCreationMessage: "",
      selectedTeam: "",
      yourTeams: [],
      clicked: false,
      rosterPlayers: [],
      invitedPlayers: [],
      selectedPlayer: "",
      selectedMenu: 1,
    };
  },
  methods: {
    updateDataState(payment) {
      this.payment = payment;
      this.selectedInvitation = null;
      this.getCurrentPlayerRegistrations();
    },
    async cancelInvite(id) {
      await axios.post(
        this.$apiUrl + "/registration/team/cancelInvite",
        {
          Invitation_id: id,
        },
        {
          headers: {
            token: `${localStorage.userToken}`,
          },
        }
      );
      this.getInvitedPlayers();
      this.getInvitations();
    },
    async lookRegistration() {
      for (let reg of this.teamRegistrations) {
        if (reg.Season_id == this.selectedRegistration.season_id) {
          this.alreadyRegistered = true;
          return;
        }
      }
      this.alreadyRegistered = false;
      this.message = "";
    },
    async getRegistrations() {
      axios.get(this.$apiUrl + "/registration/player").then((res) => {
        this.registrations = res.data;
      });
    },
    async getInvitedPlayers() {
      axios
        .get(
          this.$apiUrl +
            "/registration/team/invitations?Team_id=" +
            this.selectedTeam._id +
            "&Registration_id=" +
            this.selectedRegistrationForInvite.Registration_id
        )
        .then((res) => {
          this.invitedPlayers = res.data;
        });
    },
    async getYourTeams() {
      axios
        .get(
          this.$apiUrl +
            "/capitain/teams?CapEmail=" +
            JSON.parse(atob(localStorage.userToken.split(".")[1])).Email
        )
        .then((res) => {
          this.yourTeams = res.data;
        });
    },
    async getPlayerRegistrations() {
      axios.get(this.$apiUrl + "/registration/player").then((res) => {
        this.playerRegistrations = res.data;
      });
    },
    async getCurrentPlayerRegistrations() {
      axios
        .get(
          this.$apiUrl +
            "/registration/player/registred?Player_id=" +
            this.Player_id
        )
        .then((res) => {
          this.currentPlayerRegistrations = res.data;
        });
    },
    async getTeamRegistrations() {
      axios
        .get(
          this.$apiUrl +
            "/registration/team/registrations?Team_id=" +
            this.selectedTeam._id
        )
        .then((res) => {
          this.teamRegistrations = res.data;
        });
    },
    async getRosterPlayers() {
      axios
        .get(
          this.$apiUrl +
            "/registration/team/players?Team_id=" +
            this.selectedTeam._id +
            "&Registration_id=" +
            this.selectedRegistrationForInvite.Registration_id
        )
        .then((res) => {
          this.rosterPlayers = res.data;
        });
    },
    async lookRegistrationPlayer() {
      for (let reg of this.currentPlayerRegistrations) {
        if (reg.Registration_id == this.selectedRegistrationPlayer._id) {
          this.alreadyRegisteredPlayer = true;
          return;
        }
      }
      this.alreadyRegisteredPlayer = false;
      this.messagePlayer = "";
    },
    async invite() {
      await axios
        .post(
          this.$apiUrl + "/registration/team/invite",
          {
            League_id: this.selectedRegistrationForInvite.League_id,
            Season_id: this.selectedRegistrationForInvite.Season_id,
            Registration_id: this.selectedRegistrationForInvite.Registration_id,
            Team_id: this.selectedTeam._id,
            Player_id: this.selectedPlayer._id,
          },
          {
            headers: {
              token: `${localStorage.userToken}`,
            },
          }
        )
        .then(async (res) => {
          this.message = res.data;
        });

      this.clicked = false;
      this.selectedPlayer = "";
      this.getInvitedPlayers();
      this.getInvitations();
    },

    async getDivisions(league_id, season_id) {
      axios
        .get(
          this.$apiUrl +
            "/registration/divisions?league_id=" +
            league_id +
            "&season_id=" +
            season_id
        )
        .then((res) => {
          this.divisions = res.data;
        });
    },
    async createTeam() {
      axios
        .post(
          this.$apiUrl + "/teams/create",
          {
            Team_Name: this.teamName,
          },
          {
            headers: {
              token: `${localStorage.userToken}`,
            },
          }
        )
        .then((res) => {
          this.teamCreationMessage = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    if (localStorage.userToken) {
      let token = localStorage.userToken;
      let decoded = jwtDecode(token);
      this.First_Name = decoded.First_Name;
      this.Last_Name = decoded.Last_Name;
      this.playerPicture = decoded.playerPicture;
      this.Player_id = decoded.Player_id;
    }
    this.getRegistrations();
    this.getPlayerRegistrations();
    this.getCurrentPlayerRegistrations();
  },
};
</script>

<style lang="scss" scoped>
.generalInfo {
  display: flex;
  width: 90%;
  margin-left: 5%;
}

.playerPicture {
  height: 200px;
  width: 200px;
}
.statTable {
  width: 90%;
  margin-left: 5%;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.589);
}
.Name {
  display: flex;
  flex-direction: column;
  font-size: 25px;

  p {
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 5px;
  }

  p:first-child {
    margin-top: 25%;
  }
}

.invitations {
  width: 80%;
  margin-left: 10%;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.589);
  margin-top: 50px;
  margin-bottom: 50px;
  height: 400px;
  overflow-y: auto;
}
.menu {
  padding: 10px 0px 20px 0px;
  height: 50px;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  .menuItem {
    flex: 1;
    line-height: 50px;
    margin: 2px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.432);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
  .menuItem:hover {
    background-color: rgba(0, 0, 0, 0.274);
    transition-duration: 0.4s;
  }
}
.activeMenu {
  background-color: rgba(19, 19, 19, 0.829);
  color: white;
}
.invitation {
  width: 80%;
  margin-left: 10%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.562);
  height: 175px;
  margin-bottom: 40px;
  text-align: center;
  padding-top: 20px;

  p {
    padding: 5px 20px 5px 20px;
  }
}
.menuTitle {
  background-color: #ffc72c;
  background-image: url(/img/partern-opt5.4a5eff33.svg),
    radial-gradient(circle, #ffc72c, #c59100);
  background-blend-mode: screen, normal;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  color: rgb(26, 26, 26);
}
.Cancel {
  background-color: rgba(255, 0, 0, 0.795);
  color: white;
  border: none;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.774);
  font-size: 13px;
  width: 100px;
  padding: 3px;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.Cancel:hover {
  background-color: rgb(255, 0, 0);
}
.ButtonAccept {
  width: 150px;
  height: 30px;
  margin-left: 20px;
  margin-top: 10px;
  padding: 5px 50px 5px 50px;
  background-color: rgb(141, 30, 3);
  color: rgb(255, 255, 255);
  font-size: 14px;
  letter-spacing: 1px;
  border: 0px solid;
  cursor: pointer;
}

.ButtonAccept:first-of-type {
  background-color: rgb(0, 148, 32);
}

.registrations {
  height: 450px;
  width: 80%;
  margin-left: 10%;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.589);
  margin-top: 50px;
  margin-bottom: 50px;
  display: block;
  overflow-y: auto;
}

.selection {
  border: 0;
  padding: 8px 0;
  border-bottom: 1px solid rgb(66, 66, 66);
  width: 450px;
  height: 37px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  margin-bottom: 20px;
}

.Button {
  width: 250px;
  height: 40px;
  margin-top: 30px;
  padding: 5px 50px 5px 50px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 15px;
  letter-spacing: 1px;
  border: 0px solid;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  cursor: pointer;
}

.Button:disabled {
  background-color: rgb(131, 131, 131);
}

.paymentInfo {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
}

.registered {
  text-align: center;
  padding: 10px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.39);
}

.loginInput {
  border: 0;
  padding: 7px 0;
  border-bottom: 1px solid #ccc;
  width: 450px;
  height: 25px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}

#LoginButton {
  transform: translateX(-50%);
  margin: 30px 0px 25px 50%;
  padding: 10px;
  width: 300px;
  border: 0px solid black;
  box-shadow: 0px 0px 3px 0px;
  font-size: 18px;
  background-color: rgb(0, 151, 13);
  color: white;
  cursor: pointer;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.678);
}

.selector {
  margin-top: 10px;
  display: flex;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 300px;
}

.playerCard {
  width: 15%;
  height: 230px;
  margin: 2.5%;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.329);
  cursor: pointer;
}

.plus {
  font-size: 80px;
  text-align: center;
  line-height: 190px;
  color: rgba(0, 0, 0, 0.801);
}

.cards {
  display: flex;
  flex-wrap: wrap;
}
.popup {
  background-color: #000000b6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .box {
    width: 40%;
    height: auto;
    z-index: 1010;
    box-shadow: #00000077 0 3px 7px;
    border: #555;
    background-color: white;
  }

  .background {
    background-color: #1d1d1d81;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1005;
  }
}
.invite {
  text-align: center;
  color: rgba(0, 170, 0, 0.801);
  padding: 5px;
}
</style>
