<template>
  <div class="search-player">
    <input
      type="search"
      :placeholder="$t('home.findPlayer')"
      :value="query"
      @input="onInput"
    />
    <ul class="list" v-show="expanded">
      <li
        v-for="(item, index) in result"
        :key="index"
        v-bind:class="{ alreadyRegistered: item.alreadyRegistered }"
        @click="$emit('search-function', item)"
      >
        <img class="playerPicture" src="@/assets/default-player.png" />
        {{ item.Last_Name }}, {{ item.First_Name }}
        <p v-if="item.alreadyRegistered">Ready to play</p>
        <p v-if="item.Last_Team" style="display: inline;">
          | {{ item.Last_Team }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["registrationID"],
  data() {
    return {
      query: "",
      result: [],
      expanded: false,
    };
  },
  methods: {
    onInput(ev) {
      this.query = ev.target.value;
      if (this.query.length >= 3) {
        this.expanded = true;
        window.addEventListener("click", this.close);
        axios
          .get(
            this.$apiUrl +
              "/search/players-to-invite?playerName=" +
              this.query +
              "&registrationID=" +
              this.registrationID
          )
          .then((res) => {
            this.result = res.data;
          });
      } else {
        this.expanded = false;
        this.result = [];
        window.removeEventListener("click", this.close);
      }
    },
    close() {
      this.expanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.search-player {
  margin-bottom: 15px;
  position: relative;
  input[type="search"] {
    width: 100%;
    font-size: 20px;
    padding: 10px;
  }
  .list {
    position: absolute;
    width: 100%;
    background-color: #eee;
    cursor: pointer;
    li {
      list-style: none;
      padding: 10px;
    }
    li:hover {
      background-color: #ddd;
    }
  }
}
.alreadyRegistered {
  background-color: rgba(1, 14, 201, 0.507);
}
.playerPicture {
  width: 20px;
  height: 20px;
}
</style>
